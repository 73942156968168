import React, { Component } from 'react';

import './slideshow.scss';

class Slideshow extends Component {
    render() {
        return (
            // Code for slideshow: https://css-tricks.com/infinite-all-css-scrolling-slideshow/
            <div class="tech-slideshow">
                <div class="mover-1"></div>
            </div>
        )
    }
}

export default Slideshow;