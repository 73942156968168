import React, { Component } from 'react';

import './eventslide.scss';

class EventSlide extends Component {
    render() {
        return (
            // Code for slideshow: https://css-tricks.com/infinite-all-css-scrolling-slideshow/
            <div class="tech-slideshow2">
                <div class="mover2-1"></div>
            </div>
        )
    }
}

export default EventSlide;